@font-face {
  src: url("https://use.typekit.net/rvg4ypo.css") format("rvg4ypo");
  font-family: como, sans-serif;
  font-weight: 100;
}

@font-face {
  src: url("https://use.typekit.net/rvg4ypo.css") format("rvg4ypo");
  font-family: como, sans-serif;
  font-weight: 200;
}

@font-face {
  src: url("https://use.typekit.net/rvg4ypo.css") format("rvg4ypo");
  font-family: como, sans-serif;
  font-weight: 300;
}

@font-face {
  src: url("https://use.typekit.net/rvg4ypo.css") format("rvg4ypo");
  font-family: como, sans-serif;
  font-weight: 400;
}

@font-face {
  src: url("https://use.typekit.net/rvg4ypo.css") format("rvg4ypo");
  font-family: como, sans-serif;
  font-weight: 500;
}

@font-face {
  src: url("https://use.typekit.net/rvg4ypo.css") format("rvg4ypo");
  font-family: como, sans-serif;
  font-weight: 600;
}

@font-face {
  src: url("https://use.typekit.net/rvg4ypo.css") format("rvg4ypo");
  font-family: como, sans-serif;
  font-weight: 700;
}

@font-face {
  src: url("https://use.typekit.net/rvg4ypo.css") format("rvg4ypo");
  font-family: como, sans-serif;
  font-weight: 800;
}

@font-face {
  src: url("https://use.typekit.net/rvg4ypo.css") format("rvg4ypo");
  font-family: como, sans-serif;
  font-weight: 900;
}
