@use "sass:math";
@import "mixins";

@mixin size($height: 22px) {
  border-radius: 5px;
  height: $height;
}

.tag {
  @include size;
  color: var(--button-primary-text);
  font-size: var(--font-size-small);
  font-weight: var(--bold);
  padding-left: 12px;
  padding-right: 12px;
  margin: 2px;
  padding: 6px;
}

.bg-disable {
  @include size;
  color: var(--button-primary-text);
  font-size: var(--font-size-small);
  font-weight: var(--bold);
  padding-left: 12px;
  padding-right: 12px;
  margin: 2px;
  padding: 6px;
  background-color: #cccccc;
}

.small {
  @include size(18px);
  font-size: 9px;
}
