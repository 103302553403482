@import "mixins";

:root {
  // sizes
  --border-width: 1px;
  --border-radius: 8px;
  --grid-gap: 20px;
  --card-padding: 28px;
  --card-padding-small: 20px;
  --header-height: 64px;
  --max-width: 1080px;
  --max-width-small: 640px;
  --modal-width: 520px;
  --input-height: 45px;

  // fonts
  --font-family-monospace: como, sans-serif;
  --font-size: 14px;
  --font-size-small: 12px;
  --normal: 400;
  --bold: 500;

  // components
  --box-shadow: 0 4px 8px 0 hsl(0 0% 0% / 0.2);

  // miscellaneous
  --transition: 100ms;

  @include desktop {
    --main-padding-horizontal: 64px;
  }

  @include mobile {
    --main-padding-horizontal: 20px;
  }
}

:root {
  --button-primary-bg-h: 189;
  --button-primary-bg-s: 85%;
  --button-primary-bg-l: 0%;
  --button-primary-bg: #bbfd00;
  --button-danger-bg-h: 356;
  --button-danger-bg-s: 100%;
  --button-danger-bg-l: 67%;
  --button-danger-bg: hsl(
    var(--button-danger-bg-h),
    var(--button-danger-bg-s),
    var(--button-danger-bg-l)
  );
  --button-primary-text: hsl(0 0% 95%);
  --button-default-bg-h: 222;
  --button-default-bg-s: 77%;
  --button-default-bg-l: 95%;
  --button-default-bg: #cccccc;
  --button-default-text: hsl(226 71% 43%);

  --menu-bg: hsl(215 100% 33%);
  --menu-border: hsl(0 0% 100% / 0.1);
  --menu-text: hsl(0 0% 91%);

  --link: #bbfd00;
  --text: #bbfd00;
  --text-muted: #999999;
  --chart: hsl(215 90% 57%);

  --bg: #1a2a3a;
  --bg-muted: hsl(229 73% 97%);
  --card-bg: #1a2a3a;
  --card-border: #0d1f31;
  --input-bg: var(--card-bg);
  --input-border: hsl(0 0% 100% / 0.1);

  --info: hsl(210 89% 61%);
  --info-light: hsl(210 89% 61% / 0.1);
  --warning: hsl(36 98% 50%);
  --warning-light: hsl(36 98% 50% / 0.1);
  --success: #0055bd;
  --success-light: hsl(168 71% 39% / 0.1);
  --danger: hsl(356 100% 67%);
  --danger-light: hsl(356 100% 67% / 0.1);
  --default: #777777;

  --extension-bg: hsl(227 62% 11%);
  --extension-text: hsl(210 89% 61%);
}
