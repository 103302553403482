.blockValue {
  background-color: #00000049;
  color: #ccc;
  padding: 6px;
  border-radius: 10px;
  text-align: center;
  font-family: como, sans-serif;
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 5px;
}
