@import "mixins";

.button {
  width: 38px;
  height: 38px;
  background-color: #000000bd;
}

.status {
  @include flex;
  color: var(--button-primary-text);
  font-size: var(--font-size-small);
  padding: 0 14px;
  height: 38px;
  background-color: #0055bd;
}

.prev {
  border-right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.next {
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
