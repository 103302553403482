.category {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0px;
  padding-right: 0px;
  color: #f3f3f3;
  width: 102px;
  margin: 4px;
  float: left;
}

.category :hover {
  color: var(--link);
  border-width: 2px;
}

.selected {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0px;
  padding-right: 0px;
  border-color: var(--link);
  color: var(--link);
  width: 102px;
  margin: 4px;
  float: left;
}

.name {
  position: relative;
  top: -4px;
}
