@import "mixins";

.amount {
  font-size: 32px;
  font-weight: var(--bold);
  color: #ffd83d;
}

.divisor {
  border-top: 1px solid var(--card-border);
  font-size: var(--font-size-small);
  margin-top: 20px;
  padding-top: 20px;
}

.title {
  font-size: 20px;
  font-weight: var(--bold);
  color: var(--link);
}

.historyRound {
  font-size: 18px;
  font-weight: bold;
  padding: 2px;
}

.formBuy {
  padding: 10px;
  margin: 10px;
}

.description {
  color: #888888;
}

.textNoConnect {
  font-size: 18px;
}

.date {
  color: #777777;
  padding: 8px;
  font-size: 12px;
}

.mutted {
  color: #777777;
  padding: 8px;
  font-size: 12px;
}

.socialIcons {
  color: var(--link);
  text-align: right;
}

.socialButton {
  padding: 8px;
}

.btnGame {
  border-radius: 5px;
  margin: 0;
  width: 100%;
  padding: 0px;
  margin: 0px;
  border: 0;
}

.btnGame :hover {
  border-radius: 5px;
  margin: 0;
  width: 100%;
  border: 0;
}

.btnEnterGame {
  border-radius: 5px;
  margin: 0;
  padding: 0;
  background-color: #0055bd;
}

.btnEmpty {
  border-radius: 5px;
  margin: 0;
  padding: 6px;
  background-color: #0055bd;
}

.available {
  color: #999999;
  font-size: 11px;
}

.btnBuy {
  background-color: #0055bd;
}

.mainToggle {
  width: 100% !important;
}

.toggle {
  color: var(--link) !important;
  background-color: #1a2a3a !important;
  margin: 1% !important;
  width: 100% !important;
}

.disputeFor {
  color: var(--link);
  background-color: #1a2a3a;
  padding: 8px;
  border-radius: 5px;
}

.msg {
  color: #999999;
  background-color: #00000065;
  padding: 8px;
  text-align: center;
  width: 100% !important;
  margin: 1px;
  border-radius: 5px;
}

.renew {
  text-align: right;
  cursor: pointer;
}

.mobileHide {
  @include mobile {
    display: none;
  }
}

.categoryList {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer {
  margin-top: 20px;
  border-top: var(--card-border) solid 1px;
  border-bottom: var(--card-border) solid 1px;
  padding: 10px;
  text-align: right;
  color: var(--text-muted);
  font-size: 12px;
}

.helpLinks {
  text-align: right;
}
