@import "mixins";

body {
  background-color: #1a2a3a;
}

.layout {
  @include desktop {
    grid-template-columns: 220px 1fr;
    grid-template-rows: auto var(--header-height) 1fr;
    grid-template-areas:
      "banner banner"
      "sidebar header"
      "sidebar main";
  }

  @include mobile {
    grid-template-rows: auto repeat(2, var(--header-height)) 1fr;
    grid-template-areas:
      "banner"
      "header"
      "main";
  }

  height: 100vh;
}

.banner {
  grid-area: banner;
  -webkit-app-region: drag;
}

@mixin border-left {
  @include desktop {
    border-left: var(--border-width) solid var(--card-border);
  }
}

.header {
  grid-area: header;
  display: grid;

  background: #1a2a3a;
  gap: 10px;
  padding: 0 var(--main-padding-horizontal);
  position: relative;
  white-space: nowrap;

  .wrapper {
    @include flex(space-between);
    width: 100%;
    height: var(--header-height);
  }

  -webkit-app-region: drag;

  button {
    -webkit-app-region: no-drag;
  }
}

.actions {
  @include flex;
  gap: 10px;
}

.main {
  grid-area: main;
  overflow: auto;
  position: relative; // for progress bar from the page
}

/* menu open */
@include mobile {
  .menu {
    grid-template-rows: auto 1fr;
    grid-template-areas: "banner";

    .header,
    .main {
      display: none;
    }
  }
}
