.block {
  background-color: #00000098;
  color: #ccc;
  padding: 8px;
  border-radius: 10px;
  font-family: como, sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 5px;
}
